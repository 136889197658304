<template>
  <div class="root">
    <lunbo></lunbo>
    <div class="applicationMenuList">
      <div @click="handleApplicationItemClick(item)" v-for="(item, index) in applicationList" :key="index"
        :class="['applicationMenuItem', item.active && 'applicationMenuItem_active']">
        <img :src="item.active ? item.activeIcon : item.inactiveIcon" class="applicationMenuItemIcon" />
        <span>{{ $t(item.nameKey) }}</span>
      </div>
    </div>
    <div class="tabsContainer">
      <borderTitle :title="$t('application.acquireTitle')" />
      <acquire />
    </div>
  </div>
</template>

<script>
import acquire from '../components/applicationPageComponents/acquire.vue';

// svg
import web from '../components/svg/web.vue';
import borderTitle from '../components/borderTitle.vue';

export default {
  name: 'application',
  components: {
    acquire,
    // svg
    web,
    borderTitle,
  },
  data() {
    return {
      applicationList: [
        {
          activeIcon: require("../assets/images/application_icon_web_active.png"),
          inactiveIcon: require("../assets/images/application_icon_web_inactive.png"),
          nameKey: "application.acquireTitle",
          active: true
        },
        {
          activeIcon: require("../assets/images/application_icon_food_active.png"),
          inactiveIcon: require("../assets/images/application_icon_food_inactive.png"),
          nameKey: "application.getFoodTitle",
          active: false
        },
        {
          activeIcon: require("../assets/images/application_icon_password_active.png"),
          inactiveIcon: require("../assets/images/application_icon_password_inactive.png"),
          nameKey: "application.generatePasswordTitle",
          active: false
        },
        {
          activeIcon: require("../assets/images/application_icon_movie_active.png"),
          inactiveIcon: require("../assets/images/application_icon_movie_inactive.png"),
          nameKey: "application.movieTitle",
          active: false
        },
        {
          activeIcon: require("../assets/images/application_icon_fly_active.png"),
          inactiveIcon: require("../assets/images/application_icon_fly_inactive.png"),
          nameKey: "application.flyTitle",
          active: false
        }
      ]
    }
  },
  methods: {
    handleTabClick(e) {
      const index = Number(e.index);
      if (index == 1 && !this.$cookie.get("userName")) {
        this.$message.error(this.$t("application.loginTips"));
        setTimeout(() => {
          this.$router.push({ path: "/login", query: { do: "login" } })
        }, 1800);
      }
    },
    handleApplicationItemClick({ active }) {
      if (!active) {
        this.$message.error(this.$t("application.comingSoonTips"));
      }
    }
  }
}
</script>

<style>
.tabsContainer {
  box-sizing: border-box;
  padding: 15px 250px;
}

.tabItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  color: #587fe9;
}

.el-tabs {
  box-sizing: border-box;
  border: 2px solid #d5e0ff !important;
}

.tabItem>svg {
  height: 20px;
  width: 20px;
}

.el-tabs__header {
  background-color: #d5e0ff !important;
  border: none !important;
}

.applicationMenuList {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.applicationMenuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px 40px;
  border-radius: 10px;
  color: #0065a8;
  gap: 30px;
  font-weight: bold;
  font-size: 18px;
  user-select: none;
  cursor: pointer;
  border: 4px solid #0065a8;
}

.applicationMenuItem_active {
  background-color: #0065a8;
  color: white;
}

.applicationMenuItemIcon {
  width: 70px;
}
</style>